import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import * as XLSX from "xlsx-js-style";
import DatePicker from "react-datepicker";
import { vi } from "date-fns/locale";
import { getWeek, startOfWeek, endOfWeek } from "date-fns";
import { UnsavedChangesHandler } from "./UnsaveChangesHandler";
import {
  Breadcrumb,
  Button,
  Card,
  Container,
  Spinner,
  Modal,
  Form,
} from "react-bootstrap";
import Select from "react-select";
import MenuFoodsModal from "./MenuFoodsModal";
import {
  exportMenu0,
  exportMenu1,
  exportMenu2,
  exportMenu3,
  exportMenu4,
  exportMenu5,
} from "./exportFunctions";
import MenuExcelTemplate0 from "./MenuExcelTemplate0";
import MenuExcelTemplate1 from "./MenuExcelTemplate1";
import MenuExcelTemplate2 from "./MenuExcelTemplate2";
import MenuExcelTemplate3 from "./MenuExcelTemplate3";
import MenuExcelTemplate4 from "./MenuExcelTemplate4";
import MenuExcelTemplate5 from "./MenuExcelTemplate5";
import Check from "../../other/Check";
import { IS_ACTIVE } from "../../../../constants";
import api from "../../../../helper/axiosInstance";
import DatePickerCustom from "../../../uiElements/DatePickerCustom";
import { datesOfWeek } from "../../../../helper/times";

export default function MenuIndex() {
  const [menuData, setMenu] = useState({});
  const [menuDays, setMenuDays] = useState([]);
  const [menuDaysRoot, setMenuDaysRoot] = useState([]);
  const [menuTimes, setMenuTimes] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateMenu, setIsUpdateMenu] = useState(false);

  const [foodOptions, setFoodOptions] = useState([]);
  const [classGroups, setClassGroups] = useState([]);

  const [classGroupOptions, setClassGroupOptions] = useState([]);
  const [selectedClassGroup, setSelectedClassGroup] = useState({
    id: 0,
    group_name: "Tất cả",
  });
  const [modalShow, setModalShow2] = useState(false);
  const [optionModal, setOptionModal] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [foodModal, setFoodModal] = useState({
    id_day: "",
    day_name: "",
    id_time: "",
    time_name: "",
    foods: [],
  });

  const [weekInMonth, setWeekInMonth] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [termDate, setTermDate] = useState(new Date());
  const [school, setSchool] = useState({});

  const [showCopyModal, setShowCopyModal] = useState(false);
  const [haveFood, setHaveFood] = useState(true);
  const [mondayCopy, setMondayCopy] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const [sundayCopy, setSundayCopy] = useState(
    endOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const [mondayPaste, setMondayPaste] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const [sundayPaste, setSundayPaste] = useState(
    endOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const [hasChanges, setHasChanges] = useState(false);
  const [showIngredient, setShowIngredient] = useState(false);
  const [category, setCategories] = useState(false);

  useEffect(() => {
    document.title = "Thực đơn tuần";

    let initialWeek = setDatesOfWeek(new Date());
    setWeekInMonth((0 | (initialWeek[0].getDate() / 7)) + 1);

    getMenu(initialWeek[0], initialWeek[6]);
    getListFoods();
    getListClassGroups();
    getDetailschool();
    getListCategories();
  }, []);

  useEffect(() => {
    menuDays?.length > 0 && setHasChanges(!(menuDaysRoot == menuDays));
  }, [menuDays]);

  const handleXportWeekQuantitativeKitchen = () => {
    exportWeekQuantitativeExcel();
  };

  const exportWeekQuantitativeExcel = () => {
    const wb = XLSX.utils.book_new();

    const ws = exportMenu2(
      menuDays.length,
      menuTimes,
      menuDays,
      selectedClassGroup
    );

    XLSX.utils.book_append_sheet(wb, ws, "Thực đơn ăn chính");
    XLSX.writeFile(
      wb,
      `Thực đơn ăn chính (${stringLastDate(startDate)} - ${stringLastDate(
        termDate
      )}).xlsx`
    );
    setModalShow2(false);
  };
  // API
  const getDetailschool = async () => {
    await api
      .get(`/detailSchool`)
      .then((res) => {
        if (res.data.status === true) {
          setSchool(res.data.data[0]);
        } else {
          toast(res.data.message, { type: "error" });
        }
      })
      .catch((error) => {});
  };
  const getMenu = async (startDate, endDate) => {
    setIsLoading(true);
    setMenu({});
    setMenuDays([]);
    setMenuTimes([]);
    await api
      .get(`menu-show`, {
        params: {
          start_at: startDate.getTime() / 1000,
          term_at: endDate.getTime() / 1000,
        },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          const times = res.data.times || [];
          const days = res.data.days || [];
          setMenuDaysRoot(res.data.days);
          setMenu(res.data.menu);
          setHasChanges(false);
          setMenuDays(days);
          setMenuTimes(res.data.times?.filter((e) => e.time_name != null));

          if (
            times?.some((e) => e.time_name === null) &&
            days?.every((day) =>
              day.times.every((time) => time.foods.length === 0)
            )
          )
            return deleteMenu(res.data.menu.id_menu);

          let checkFoodsLength = false;
          if (days.length > 0) {
            for (let day of days) {
              for (let time of day.times) {
                if (time.foods.length > 0) {
                  checkFoodsLength = true;
                }
              }
            }
          } else {
            checkFoodsLength = true;
          }

          setHaveFood(checkFoodsLength);
        } else {
          toast(res.data.message, { type: "error" });
        }
      })
      .catch((error) => {
        toast(error.message, { type: "error" });
      });
  };

  const getListFoods = async () => {
    await api
      .get(`/food-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          setFoodOptions(
            res.data.foods.map((foodItem) => {
              return {
                id: foodItem.id,
                food_name: foodItem.food_name,
                id_category: foodItem.id_category,
                category_name: foodItem.category_name,
                ingredents: foodItem.ingredients || [],
              };
            })
          );
        }
      })
      .catch((error) => {});
  };
  const getListCategories = async () => {
    await api
      .get(`/category-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          setCategories(res.data.categories || []);
        }
      })
      .catch((error) => {});
  };
  const getListClassGroups = async () => {
    await api
      .get(`/group-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          setClassGroups(res.data.groups);

          const classGroupOption = res.data.groups.map((group) => {
            return {
              id: group.id,
              group_name: group.group_name,
            };
          });

          classGroupOption.unshift({ id: 0, group_name: "Tất cả" });
          setClassGroupOptions(classGroupOption);
        }
      })
      .catch((error) => {});
  };

  const handleUpdateMenu = async () => {
    setIsUpdateMenu(true);
    const daysArrayString = JSON.stringify(menuDays);

    let formData = new FormData();
    formData.append("id_menu", menuData.id_menu);
    formData.append("start_at", menuData.start_at);
    formData.append("term_at", menuData.term_at);
    formData.append("days", daysArrayString);

    await api({
      method: "put",
      url: "menu-update",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setIsUpdateMenu(false);
        if (res.data.errCode === 0) {
          setMenuDays([]);
          setHasChanges(false);
          getMenu(startDate, termDate);
          toast(res.data.message, { type: "success" });
        } else {
          toast(res.data.message, { type: "error" });
        }
      })
      .catch((err) => {});
  };

  const deleteMenu = async (id_menu = menuData.id_menu) => {
    await api({
      method: "delete",
      url: `menu-destroy?id=${id_menu}`,
    }).then((res) => {
      if (res.data.errCode === 0) {
        setMenuDays([]);
        getMenu(startDate, termDate);
      } else {
        toast(res.data.message, { type: "error" });
      }
    });
  };

  const handleDestroyMenu = async () => {
    swal({
      title: `Bạn chắc muốn xóa thực đơn?`,
      text: `Tháng ${
        startDate.getMonth() + 1
      } (Tuần ${weekInMonth}) ${stringFirstDate(startDate)} - ${stringLastDate(
        termDate
      )} `,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then(async (ok) => {
      if (ok) {
        await api({
          method: "delete",
          url: `menu-destroy?id=${menuData.id_menu}`,
        }).then((res) => {
          if (res.data.errCode === 0) {
            setMenuDays([]);
            getMenu(startDate, termDate);
            toast(res.data.message, { type: "success" });
          } else {
            toast(res.data.message, { type: "error" });
          }
        });
      }
    });
  };

  const handleUnSavedMealDaily = async (id_menu_day) => {
    swal({
      title: "Bạn muốn hủy tính khẩu phần ăn?",
      icon: "error",
      buttons: ["Đóng", "Hủy"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        api
          .delete(`/meals-daily-unsaved`, {
            params: {
              id_menu_day: id_menu_day,
            },
          })
          .then((res) => {
            if (res.data.errCode === 0) {
              setMenuDays([]);
              getMenu(startDate, termDate);
              toast(res.data.message, { type: "success" });
            } else {
              toast(res.data.message, { type: "error" });
            }
          });
      }
    });
  };
  // API

  // Logic
  const handleExportMenu = () => {
    const wb = XLSX.utils.book_new();
    const ws = exportMenu0(menuDays.length, menuTimes.length);

    XLSX.utils.book_append_sheet(wb, ws, "Thực đơn");
    XLSX.writeFile(wb, "Thực đơn cho học sinh.xlsx");
  };

  const handleExportMenu4 = () => {
    const wb = XLSX.utils.book_new();
    const ws = exportMenu4(
      menuDays.length,
      menuTimes,
      menuDays,
      selectedClassGroup
    );

    XLSX.utils.book_append_sheet(wb, ws, "Thực đơn");
    XLSX.writeFile(wb, "Thực đơn cho học sinh.xlsx");
  };
  const handleExportMenu5 = () => {
    exportMenu5(menuDays.length);
  };
  const handleExportMenu3 = () => {
    const wb = XLSX.utils.book_new();
    const ws = exportMenu3(
      menuDays.length,
      menuTimes,
      menuDays,
      selectedClassGroup
    );

    XLSX.utils.book_append_sheet(wb, ws, "Thực đơn");
    XLSX.writeFile(wb, "Thực đơn cho học sinh.xlsx");
  };

  const handleExportMenu1 = () => {
    const wb = XLSX.utils.book_new();
    const ws = exportMenu1(
      menuDays.length,
      menuTimes,
      menuDays,
      selectedClassGroup
    );

    XLSX.utils.book_append_sheet(wb, ws, "Thực đơn");
    XLSX.writeFile(wb, "Thực đơn cho học sinh.xlsx");
  };

  const handleShowModal = (data) => {
    setFoodModal({
      id_day: data.id_day,
      day_name: data.day_name,
      id_time: data.id_time,
      time_name: data.time_name,
      foods: data.foods,
    });

    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);

    setMenuDays(
      menuDays?.map((dayItem) => ({
        ...dayItem,
        times: dayItem.times?.map((time) => ({
          ...time,
          foods: time?.foods?.filter((f) => f?.id > 0) || [],
        })),
      }))
    );
  };

  const onHideOptionModal = () => {
    setOptionModal(false);
  };

  const setDatesOfWeek = (theDate) => {
    const week = datesOfWeek(theDate);

    setStartDate(week[0]);
    setTermDate(week[week.length - 1]);

    setMondayPaste(startOfWeek(week[0], { weekStartsOn: 1 }));
    setSundayPaste(startOfWeek(endOfWeek(week[0], { weekStartsOn: 1 })));

    return week;
  };

  const stringFirstDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2);
  const stringLastDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear();
  // Logic

  // Render
  const RenderMenu = () => {
    if (isLoading) {
      return (
        <div className="d-flex justify-content-center ">
          <Spinner
            animation="border"
            role="status"
            variant="primary"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="visually-hidden">Đang tải thực đơn...</span>
          </Spinner>
        </div>
      );
    }

    return (
      <>
        {/* <MealPlanner /> */}
        <MenuExcelTemplate0
          menuDays={menuDays}
          setMenuDays={setMenuDays}
          menuTimes={menuTimes}
          selectedClassGroup={selectedClassGroup}
          handleUnSavedMealDaily={handleUnSavedMealDaily}
          handleShowModal={handleShowModal}
          weekInMonth={weekInMonth}
          startDate={startDate}
          termDate={termDate}
          group={classGroupOptions}
          foodOptions={foodOptions}
          showIngredient={showIngredient}
          category={category || []}
        />
        {selectedClassGroup?.id > 0 ? (
          <MenuExcelTemplate5
            menuDays={menuDays}
            menuTimes={menuTimes}
            selectedClassGroup={selectedClassGroup}
            handleUnSavedMealDaily={handleUnSavedMealDaily}
            handleShowModal={handleShowModal}
            weekInMonth={weekInMonth}
            startDate={startDate}
            termDate={termDate}
            group={classGroupOptions}
          />
        ) : null}

        <MenuExcelTemplate4
          menuDays={menuDays}
          menuTimes={menuTimes}
          selectedClassGroup={selectedClassGroup}
          weekInMonth={weekInMonth}
          startDate={startDate}
          termDate={termDate}
        />

        <MenuExcelTemplate3
          menuDays={menuDays}
          menuTimes={menuTimes}
          selectedClassGroup={selectedClassGroup}
          weekInMonth={weekInMonth}
          startDate={startDate}
          termDate={termDate}
        />

        <MenuExcelTemplate1
          menuDays={menuDays}
          menuTimes={menuTimes}
          selectedClassGroup={selectedClassGroup}
          weekInMonth={weekInMonth}
          startDate={startDate}
          termDate={termDate}
        />

        {modalShow ? (
          <MenuExcelTemplate2
            school={school}
            weekInMonth={weekInMonth}
            startDate={startDate}
            endDate={termDate}
            listQuantitatives={menuDays}
            listDays={menuDays}
            listTimes={menuTimes}
            classGroups={classGroups}
            selectedClassGroup={selectedClassGroup}
            setSelectedClassGroup={setSelectedClassGroup}
            onHide={() => setModalShow2(false)}
            show={modalShow}
            handleExport={handleXportWeekQuantitativeKitchen}
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const modalCopyMenu = () => {
    const handleWeekChange = (date) => {
      setMondayCopy(startOfWeek(date, { weekStartsOn: 1 }));
      setSundayCopy(startOfWeek(endOfWeek(date, { weekStartsOn: 1 })));
    };

    return (
      <Modal
        show={showCopyModal}
        size="sm"
        onHide={() => setShowCopyModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Chọn thực đơn sao chép</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <center>
            <DatePickerCustom
              selected={mondayCopy}
              onChange={handleWeekChange}
              startDate={mondayCopy}
              endDate={sundayCopy}
              showWeekNumbers
              inline
              locale={vi}
            />

            <div>
              <strong>Tuần {getWeek(mondayCopy, { weekStartsOn: 1 })}:</strong>
              {` ${stringFirstDate(mondayCopy)} - ${stringLastDate(
                sundayCopy
              )}`}
            </div>
          </center>
        </Modal.Body>

        <Modal.Footer>
          <Button
            size="sm"
            variant="secondary"
            onClick={() => setShowCopyModal(false)}
          >
            <i className="fa-solid fa-times" /> Đóng
          </Button>

          <Button
            size="sm"
            variant="success"
            onClick={() => hanldeCopyMenu()}
            disabled={isLoading || isUpdateMenu}
          >
            <i className="fa-solid fa-check" /> Lưu
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const hanldeCopyMenu = async () => {
    mondayCopy.setHours(0, 0, 0, 0);
    sundayCopy.setHours(0, 0, 0, 0);
    mondayPaste.setHours(0, 0, 0, 0);
    sundayPaste.setHours(0, 0, 0, 0);

    let formData = new FormData();
    formData.append(
      "start_date_copy",
      parseInt(Math.floor(mondayCopy.getTime() / 1000))
    );
    formData.append(
      "end_date_copy",
      parseInt(Math.floor(sundayCopy.getTime() / 1000))
    );
    formData.append(
      "start_date_paste",
      parseInt(Math.floor(mondayPaste.getTime() / 1000))
    );
    formData.append(
      "end_date_paste",
      parseInt(Math.floor(sundayPaste.getTime() / 1000))
    );

    setIsLoading(true);

    await api
      .post(`/menu-copy-week`, formData)
      .then((res) => {
        setMenuDays([]);
        setShowCopyModal(false);
        setIsLoading(false);
        getMenu(startDate, termDate);
        toast(res.data.messages, { type: "success" });
      })
      .catch((e) => {
        const messages = e.response.data.messages || {};

        if (
          Object.keys(messages).length > 0 &&
          messages !== null &&
          typeof messages === "object"
        ) {
          Object.keys(messages).forEach((path) => {
            toast(messages[path], { type: "error" });
          });
        } else {
          toast(e.message, { type: "error" });
        }

        setIsLoading(false);
      });
  };

  const CustomInput = ({ value, onClick }) => (
    <Button variant="primary" onClick={onClick}>
      <i className="fa-solid fa-calendar" />
    </Button>
  );

  const handleWeekChangeMenu = (date) => {
    const startDateGMT = startOfWeek(date, { weekStartsOn: 1 });
    const endDateGMT = startOfWeek(endOfWeek(date, { weekStartsOn: 1 }));

    setWeekInMonth((0 | (startDateGMT.getDate() / 7)) + 1);
    getMenu(startDateGMT, endDateGMT);
    setStartDate(startDateGMT);
    setTermDate(endDateGMT);

    setMondayPaste(startDateGMT);
    setSundayPaste(endDateGMT);
  };
  const handleDeleteDrop = (e) => {
    e.preventDefault();

    const itemData = e.dataTransfer.getData("text/plain");

    if (itemData) {
      const draggedItem = JSON.parse(itemData);
      if (!draggedItem) return;
      setMenuDays((prevDays) => {
        return prevDays.map((day) => {
          if (
            draggedItem.type === "day" &&
            day.id_day === draggedItem.sourceDayId &&
            !day.is_meal_saved
          ) {
            return {
              ...day,
              times: day.times.map((time) => ({
                ...time,
                foods: [],
              })),
            };
          }
          if (
            draggedItem.type === "timeSlot" &&
            day.id_day === draggedItem.sourceDayId &&
            !day.is_meal_saved
          ) {
            return {
              ...day,
              times: day.times.map((time) => {
                if (time.id_time === draggedItem.sourceTimeId) {
                  return {
                    ...time,
                    foods: [],
                  };
                }
                return time;
              }),
            };
          }
          if (
            draggedItem.type === "tableCell" &&
            day.id_day === draggedItem.dayId &&
            !day.is_meal_saved
          ) {
            return {
              ...day,
              times: day.times.map((time) => {
                if (time.id_time === draggedItem.timeId) {
                  return {
                    ...time,
                    foods: time.foods.filter(
                      (food) => food.id !== draggedItem.food.id
                    ),
                  };
                }
                return time;
              }),
            };
          }
          return day;
        });
      });
      menuDays?.filter((e) => e.id_day === draggedItem.sourceDayId)?.[0]
        ?.is_meal_saved
        ? toast.error("Đã lưu tính khẩu phần ăn")
        : draggedItem.type !== "foodOption" &&
          toast.success("Đã xóa thành công!", {
            autoClose: 1500,
          });
      e.dataTransfer.clearData();
    } else {
      !menuDays.some((e) => e.is_meal_saved === IS_ACTIVE.TRUE) &&
        handleDestroyMenu();
    }
  };
  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item active>Thực đơn</Breadcrumb.Item>
        <Breadcrumb.Item active>Thực đơn tuần v2</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>
          <div className="d-block d-md-flex justify-content-between">
            <div className="d-block d-md-flex align-items-center">
              <Card.Title className="me-2">
                Thực đơn tháng {startDate.getMonth() + 1} (Tuần {weekInMonth}){" "}
                {stringFirstDate(startDate)} - {stringLastDate(termDate)}
              </Card.Title>

              <div className="d-flex mb-2 mb-md-0 align-items-center">
                <div className="me-2 d-flex align-items-center">
                  <DatePicker
                    selected={startDate}
                    startDate={startDate}
                    endDate={termDate}
                    onChange={handleWeekChangeMenu}
                    showWeekNumbers
                    dateFormat="yyyy-MM-dd"
                    calendarClassName="custom-calendar"
                    customInput={<CustomInput />}
                    locale={vi}
                  />
                </div>

                <Select
                  options={classGroupOptions}
                  placeholder={<div>Tất cả</div>}
                  getOptionLabel={(option) => option.group_name}
                  getOptionValue={(option) => option.id}
                  value={selectedClassGroup}
                  onChange={(choice) => {
                    setSelectedClassGroup(choice);
                  }}
                />
                {selectedClassGroup?.id > 0 ? (
                  <Form.Check
                    type="switch"
                    checked={showIngredient}
                    onChange={(e) => {
                      setShowIngredient(e.target.checked ? true : false);
                    }}
                    label="Xem nguyên liệu"
                    className="ms-2"
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="d-flex flex-wrap">
              {!haveFood && new Check().permission(["53"]) ? (
                <Button
                  size="sm"
                  variant="primary"
                  onClick={() => setShowCopyModal(true)}
                  className="me-2 mb-2 mb-md-0"
                  disabled={isLoading || isUpdateMenu}
                  title="Sao chép thực đơn"
                >
                  <i className="fa-solid fa-copy" /> Sao chép
                </Button>
              ) : (
                <></>
              )}
              <Button
                variant="danger"
                id="delete-food"
                size="sm"
                className="me-2 mb-2 mb-md-0"
                title="Xóa thực đơn"
                disabled={isLoading || isUpdateMenu}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDeleteDrop}
                onClick={() =>
                  !menuDays.some((e) => e.is_meal_saved === IS_ACTIVE.TRUE) &&
                  handleDestroyMenu()
                }
              >
                <i className="fa-solid fa-trash" /> Xóa
              </Button>

              {new Check().permission(["52"]) && haveFood ? (
                <>
                  <Button
                    size="sm"
                    variant="success"
                    onClick={() => setOptionModal(true)}
                    className="me-2 mb-2 mb-md-0"
                    hidden={selectedClassGroup?.id === 0}
                    disabled={
                      isLoading || isUpdateMenu || menuTimes.length === 0
                    }
                  >
                    <i className="fa-solid fa-file-export" /> Xuất file
                  </Button>

                  <Button
                    size="sm"
                    variant="success"
                    onClick={() => {
                      if (selectedClassGroup.id === 0) {
                        handleExportMenu();
                      }
                    }}
                    className="me-2 mb-2 mb-md-0"
                    hidden={selectedClassGroup?.id !== 0}
                    disabled={
                      isLoading || isUpdateMenu || menuTimes.length === 0
                    }
                  >
                    <i className="fa-solid fa-file-export" /> Xuất file
                  </Button>
                </>
              ) : (
                <></>
              )}

              {new Check().permission(["53", "54"], "or") ? (
                <Button
                  size="sm"
                  variant="success"
                  onClick={() => handleUpdateMenu()}
                  disabled={isLoading || isUpdateMenu || menuTimes.length === 0}
                  className="mb-2 mb-md-0"
                >
                  <i className="fa-solid fa-check" /> Lưu thực đơn
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Card.Header>
        <Card.Body>{RenderMenu()}</Card.Body>
      </Card>
      {showModal ? (
        <MenuFoodsModal
          show={showModal}
          onHide={handleCloseModal}
          foodModal={foodModal}
          setFoodModal={setFoodModal}
          foodOptions={foodOptions}
          classGroups={classGroups}
        />
      ) : null}

      <Modal show={optionModal} size="sm" onHide={() => onHideOptionModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Chọn mẫu file cần xuất</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center d-grid">
          <Button
            size="sm"
            variant="success"
            onClick={() => {
              if (selectedClassGroup.id !== 0) {
                handleExportMenu1();
              }
            }}
            className="mb-2"
            disabled={isLoading || isUpdateMenu || menuTimes.length === 0}
          >
            <i className="fa-solid fa-file-export" /> Mẫu số 1
          </Button>
          <Button
            size="sm"
            variant="success"
            onClick={() => setModalShow2(true)}
            className="mb-2"
            disabled={selectedClassGroup?.id === 0}
          >
            <i className="fa-solid fa-file-export" /> Mẫu số 2
          </Button>
          <Button
            size="sm"
            variant="success"
            onClick={() => {
              if (selectedClassGroup.id !== 0) {
                handleExportMenu3();
              }
            }}
            className="mb-2"
            disabled={isLoading || isUpdateMenu || menuTimes.length === 0}
          >
            <i className="fa-solid fa-file-export" /> Mẫu số 3
          </Button>
          <Button
            size="sm"
            variant="success"
            onClick={() => {
              if (selectedClassGroup.id !== 0) {
                handleExportMenu4();
              }
            }}
            className="mb-2 "
            disabled={isLoading || isUpdateMenu || menuTimes.length === 0}
          >
            <i className="fa-solid fa-file-export" /> Mẫu số 4
          </Button>
          <Button
            size="sm"
            variant="success"
            onClick={() => {
              if (selectedClassGroup.id !== 0) {
                handleExportMenu5();
              }
            }}
            className="mb-2 "
            disabled={isLoading || isUpdateMenu || menuTimes.length === 0}
          >
            <i className="fa-solid fa-file-export" /> Mẫu số 5
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={onHideOptionModal}>
            <i className="fa-solid fa-times" /> Đóng
          </Button>
        </Modal.Footer>
      </Modal>
      {hasChanges && <UnsavedChangesHandler hasChanges={hasChanges} />}
      {modalCopyMenu()}
    </Container>
  );
}
