import { useState, useEffect } from "react";
import {
  Button,
  Form,
  InputGroup,
  Modal,
  Table,
  Row,
  Col,
} from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import Select from "react-select";
import { toast } from "react-toastify";
import api from "../../../../helper/axiosInstance";

const MenuFoodsModal = ({
  show,
  onHide,
  foodModal,
  setFoodModal,
  foodOptions,
  classGroups,
}) => {
  const [categories, setCategories] = useState([]);
  const [choiceCategory, setChoiceCategory] = useState({});
  const [foodChoice, setFoodChoice] = useState({
    id: 0,
    food_name: "Chọn món",
  });

  useEffect(() => {
    getListCategories();
  }, []);

  const getListCategories = async () => {
    await api
      .get(`/category-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          let arr = [];
          arr.push({ id: 0, category_name: "Tất cả" });
          res.data.categories?.map((item) => arr.push(item));
          setCategories(arr);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {});
  };
  return (
    <Modal show={show} size="lg" onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>
          {foodModal.day_name} - Buổi {foodModal.time_name}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Table>
          <thead>
            <tr>
              <th>Món ăn</th>
              <th>Danh mục</th>
              {classGroups?.map((groupItem) => {
                return (
                  <th key={`groupItem0_${groupItem.id}`}>
                    {groupItem.group_name}
                  </th>
                );
              })}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {foodModal.foods.map((foodItem, keyFood) => {
              return (
                <tr key={foodItem.id}>
                  <td>{foodItem.food_name}</td>
                  <td>{foodItem.category_name}</td>
                  {classGroups?.map((groupItem, keyGroup) => {
                    let groupWithFood = [];
                    let keyGroupWithFood = -1;
                    foodItem?.groups.map((groupOfFood, iGroupOfFood) => {
                      if (Number(groupOfFood.id) === groupItem.id) {
                        groupWithFood[iGroupOfFood] = groupOfFood;
                        keyGroupWithFood = iGroupOfFood;
                      }
                      return groupOfFood;
                    });
                    const groupUse = foodItem.groups.filter(
                      (item) => item.id > 0
                    );
                    let more_meal =
                      groupWithFood.length > 0
                        ? groupWithFood[keyGroupWithFood].more_meal
                        : "";

                    const indexGroupUncheck = foodItem?.groups?.findIndex(
                      (g) => g?.id === groupItem.id
                    );

                    return (
                      <td key={`groupItem_${groupItem.id}`}>
                        <div
                          className="d-flex align-content-center"
                          title={`${
                            foodItem.groups?.some(
                              (item) => item.id === groupItem.id
                            )
                              ? "Hủy chọn"
                              : "Chọn"
                          } nấu cho nhóm ${groupItem.group_name}`}
                        >
                          <Form.Check
                            type="checkbox"
                            checked={foodItem.groups?.some(
                              (item) => item.id === groupItem.id
                            )}
                            value={groupItem.id}
                            onChange={(e) => {
                              if (e.target.checked) {
                                if (indexGroupUncheck === -1) {
                                  foodItem.groups.push({
                                    id: groupItem.id,
                                    group_name: groupItem.group_name,
                                    more_meal: null,
                                    ingredents: [],
                                  });
                                } else {
                                  foodItem.groups[keyGroup] = {
                                    id: groupItem.id,
                                    group_name: groupItem.group_name,
                                    more_meal: null,
                                    ingredents: [],
                                  };
                                }

                                foodItem.groups = foodItem.groups.filter(
                                  (group) => group
                                );
                              } else {
                                delete foodItem.groups[indexGroupUncheck];
                              }

                              const groupsNoEmpty = foodItem?.groups?.filter(
                                (group) => group
                              );

                              if (groupsNoEmpty.length > 0) {
                                foodModal.foods[keyFood] = foodItem;
                              } else {
                                delete foodModal.foods[keyFood];
                              }

                              setFoodModal({
                                ...foodModal,
                              });

                              if (groupUse.length === foodItem.groups.length) {
                                foodItem.groups.map(
                                  (groupUpdate, indexUpdate) => {
                                    foodItem.groups[indexUpdate].more_meal = "";
                                    foodModal.foods[keyFood] = foodItem;
                                    setFoodModal({
                                      ...foodModal,
                                    });

                                    return groupUpdate;
                                  }
                                );
                              }
                            }}
                            style={{ maxWidth: "1.5rem", marginRight: ".5rem" }}
                            disabled={
                              groupUse.length === 1 &&
                              foodItem.groups?.some(
                                (item) => item.id === groupItem.id
                              )
                            }
                          />

                          {groupWithFood.length > 0 && groupUse.length === 1 ? (
                            <CurrencyFormat
                              style={{
                                width: 69,
                                height: 27,
                              }}
                              className="form-control text-end"
                              thousandSeparator
                              value={more_meal === null ? "" : more_meal}
                              allowNegative={false}
                              onValueChange={(values) => {
                                foodModal.foods[keyFood].groups[
                                  keyGroupWithFood
                                ].more_meal = values.value;
                                setFoodModal({
                                  ...foodModal,
                                });
                              }}
                              title="Nhập số lượng nấu thêm (Nếu có!)"
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                    );
                  })}
                  <td>
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() => {
                        delete foodModal.foods[keyFood];
                        setFoodModal({
                          ...foodModal,
                          foods: foodModal?.foods,
                        });
                      }}
                      title="Xóa món"
                    >
                      <i className="fa-solid fa-times" />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        <Row>
          <Col className="mb-2">
            <InputGroup>
              <InputGroup.Text style={{ width: "6em" }}>
                Danh mục
              </InputGroup.Text>

              <Select
                options={categories}
                getOptionLabel={(option) => option.category_name}
                getOptionValue={(option) => option.id}
                placeholder="Tất cả"
                onChange={(choice) => {
                  setChoiceCategory(choice);
                  setFoodChoice({ id: 0, food_name: "Chọn món" });
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "16em",
                  }),
                }}
              />
            </InputGroup>
          </Col>

          <Col>
            <InputGroup>
              <InputGroup.Text style={{ width: "6em" }}>Món ăn</InputGroup.Text>

              <Select
                options={
                  choiceCategory.id
                    ? foodOptions
                        ?.filter((e) => e.id_category == choiceCategory.id)
                        ?.filter(
                          (foodItem) =>
                            !foodModal.foods.some(
                              (food) => food.id === foodItem.id
                            )
                        )
                    : foodOptions?.filter(
                        (foodItem) =>
                          !foodModal.foods.some(
                            (food) => food.id === foodItem.id
                          )
                      )
                }
                getOptionLabel={(option) => option.food_name}
                getOptionValue={(option) => option.id}
                value={foodChoice}
                placeholder="Nhập tên món ăn"
                onChange={(choice) => {
                  let foods = foodModal.foods;
                  let groups = [];
                  setFoodChoice(choice);
                  classGroups.map((group) => {
                    groups.push({
                      id: group.id,
                      group_name: group.group_name,
                      more_meal: "",
                      ingredents: [],
                    });
                    return group;
                  });

                  if (
                    !foods.some((food) => Number(food.id) === Number(choice.id))
                  ) {
                    foods.push({
                      id: choice.id,
                      food_name: choice.food_name,
                      id_category: choice.id_category,
                      category_name: choice.category_name,
                      priority: 1,
                      groups: groups,
                    });

                    foodModal.foods = foods;

                    setFoodModal({ ...foodModal });
                  } else {
                    toast("Bạn đã chọn món ăn này rồi", {
                      type: "error",
                      autoClose: 1000,
                    });
                  }
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "16em",
                  }),
                }}
              />
            </InputGroup>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={() => onHide()}>
          <i className="fa-solid fa-times" /> Đóng
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MenuFoodsModal;
