import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { toSlug } from "../../utils/helpers";
import CurrencyFormat from "react-currency-format";
const MenuExcelTemplate0 = ({
  menuDays,
  setMenuDays,
  menuTimes,
  selectedClassGroup,
  handleUnSavedMealDaily,
  handleShowModal,
  group,
  startDate,
  termDate,
  weekInMonth,
  foodOptions: foods,
  showIngredient,
  category,
}) => {
  const [foodOptions, setFoodOptions] = useState(foods);
  const [draggedItem, setDraggedItem] = useState(null);
  const [touchItem, setTouchItem] = useState(null);
  const [lastDroppedCell, setLastDroppedCell] = useState(null);
  const [lastHoverCell, setLastHoverCell] = useState(null);
  const [highlightedColumn, setHighlightedColumn] = useState(null);
  const [highlightedTimeSlots, setHighlightedTimeSlots] = useState(null);
  const [hoverColumn, setHoverColumn] = useState(null);
  const [choiceCategory, setChoiceCategory] = useState(0);
  const [foodSearch, setFoodSearch] = useState();

  const stringFirstDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2);
  const stringLastDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear();
  useEffect(() => {
    if (lastDroppedCell || highlightedColumn) {
      const timer = setTimeout(() => {
        setLastDroppedCell(null);
        setHighlightedColumn(null);
      }, 700);
      return () => clearTimeout(timer);
    }
  }, [lastDroppedCell, highlightedColumn]);

  useEffect(() => {
    handleSearchFood(choiceCategory, foodSearch);
  }, [choiceCategory, foodSearch]);
  const handleSearchFood = (choiceCategory, foodSearch) => {
    setFoodOptions(
      foods?.filter(
        (food) =>
          toSlug(food?.food_name)?.includes(toSlug(foodSearch || "")) &&
          (Number(food?.id_category) === Number(choiceCategory) ||
            Number(choiceCategory) === 0)
      )
    );
  };

  const handleTouchStart = (e, source) => {
    if (source.type === "foodOption") {
      setTouchItem({ type: "foodOption", food: source.food });
      setDraggedItem({ type: "foodOption", food: source.food });
    } else if (source.type === "day") {
      setTouchItem({
        type: "day",
        sourceDayId: source.dayId,
        dayData: source.dayData,
      });
      e.currentTarget.classList.add("opacity-50");
    } else if (source.type === "timeSlot") {
      if (!source.foods?.length) return;
      setTouchItem({
        type: "timeSlot",
        sourceDayId: source.dayId,
        sourceTimeId: source.timeId,
        foods: [...source.foods],
      });
    } else if (source.type === "tableCell") {
      setTouchItem({
        type: "tableCell",
        dayId: source.dayId,
        timeId: source.timeId,
        food: source.food,
      });
    }
  };
  const handleDragStart = (e, source) => {
    if (source.type === "foodOption") {
      setDraggedItem({ type: "foodOption", food: source.food });
    } else if (source.type === "day") {
      e.dataTransfer.setData(
        "text/plain",
        JSON.stringify({
          type: "day",
          sourceDayId: source.dayId,
          dayData: source.dayData,
        })
      );

      setDraggedItem({
        type: "day",
        sourceDayId: source.dayId,
        dayData: source.dayData,
      });
      e.currentTarget.classList.add("opacity-50");
    } else if (source.type === "timeSlot") {
      if (!source.foods?.length) return;
      setDraggedItem({
        type: "timeSlot",
        sourceDayId: source.dayId,
        sourceTimeId: source.timeId,
        foods: [...source.foods],
      });
      e.dataTransfer.setData(
        "text/plain",
        JSON.stringify({
          type: "timeSlot",
          sourceDayId: source.dayId,
          sourceTimeId: source.timeId,
          foods: [...source.foods],
        })
      );
    } else if (source.type === "tableCell") {
      setDraggedItem({
        type: "tableCell",
        dayId: source.dayId,
        timeId: source.timeId,
        food: source.food,
      });
      e.dataTransfer.setData(
        "text/plain",
        JSON.stringify({
          type: "tableCell",
          dayId: source.dayId,
          timeId: source.timeId,
          food: source.food,
        })
      );
    }
  };

  const handleDragEnd = (e) => {
    e.currentTarget.classList.remove("opacity-50");
    setHighlightedTimeSlots(null);
    setLastHoverCell(null);
    e?.dataTransfer?.clearData();
  };
  const handleTouchMove = (e) => {
    const touch = e.touches[0];
    const targetElement = document.elementFromPoint(
      touch.clientX,
      touch.clientY
    );

    if (targetElement && draggedItem) {
      targetElement.classList.add("bg-red", "bg-opacity-10");
    }
  };

  const handleTouchEnd = (e) => {
    const touch = e.changedTouches[0];
    const targetElement = document.elementFromPoint(
      touch.clientX,
      touch.clientY
    );

    if (targetElement) {
      targetElement.classList.remove("bg-info", "bg-opacity-10");
      const targetDayId = targetElement.dataset.dayId;
      const targetTimeId = targetElement.dataset.timeId;

      if (targetDayId || targetTimeId) {
        const targetDay = menuDays.find(
          (day) => day.id_day === parseInt(targetDayId)
        );
        const targetTime = targetDay?.times.find(
          (time) => time.id_time === parseInt(targetTimeId)
        );

        if (targetDay && targetTime) {
          handleDrop(e, targetDay, targetTime);
        }
      }
    }
    setDraggedItem(null);
    e?.dataTransfer?.clearData();
  };

  const handleDragOver = (e, dayId, timeId) => {
    e.preventDefault();
    if (draggedItem?.type === "day") {
      setHoverColumn(dayId);
    } else if (draggedItem?.type === "timeSlot" && timeId) {
      setHighlightedTimeSlots(`${dayId}_${timeId}`);
    } else if (draggedItem?.type === "foodOption" && dayId && timeId) {
      setLastHoverCell(`${dayId}_${timeId}`);
    }
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    // setHighlightedTimeSlots(null);
    setLastHoverCell(null);
    setHoverColumn(null);
  };
  const handleDrop = (e, targetDay, targetTime) => {
    e.preventDefault();

    // setHighlightedColumn(null);
    setHighlightedTimeSlots(null);
    setLastHoverCell(null);
    setDraggedItem(null);
    setHoverColumn(null);
    if (!targetDay || !draggedItem) {
      return;
    }
    if (targetDay.is_meal_saved)
      toast("Đã lưu Tính khẩu phần ăn.", {
        type: "error",
        autoClose: 1000,
      });
    else if (draggedItem?.type === "timeSlot" && targetTime?.isValidTime) {
      if (
        draggedItem.sourceDayId === targetDay.id_day &&
        draggedItem.sourceTimeId === targetTime.id_time
      ) {
        return;
      }

      setMenuDays((prevDays) => {
        return prevDays.map((day) => {
          if (day.id_day === targetDay.id_day && day.is_meal_saved === 0) {
            return {
              ...day,
              times: day.times.map((time) => {
                if (time.id_time === targetTime.id_time) {
                  const existingFoodIds = new Set(time.foods.map((f) => f.id));

                  //food new -> food
                  const newFoods = draggedItem.foods
                    // .filter((food) => !existingFoodIds.has(food.id))
                    .map((food) => ({
                      ...food,
                      groups:
                        selectedClassGroup?.id > 0
                          ? [
                              {
                                ...selectedClassGroup,
                                ingredents: food.ingredents || [],
                              },
                            ]
                          : group?.map((g) => ({
                              ...g,
                              ingredents: food.ingredents || [],
                            })),
                    }));

                  return {
                    ...time,
                    foods: [...newFoods],
                    // [...time.foods, ...newFoods],
                  };
                }
                return time;
              }),
            };
          }
          return day;
        });
      });

      setLastDroppedCell(`${targetDay.id_day}_${targetTime.id_time}`);
    } else if (draggedItem?.type === "day") {
      const targetDayId = targetDay.id_day;
      if (targetDayId === draggedItem.sourceDayId) return;
      setMenuDays((prevDays) => {
        return prevDays.map((day) => {
          if (day.id_day === targetDayId && day.is_meal_saved === 0) {
            return {
              ...day,
              times: day.times.map((time) => {
                const sourceTime = draggedItem.dayData.times.find(
                  (t) => t.id_time === time.id_time
                );
                if (sourceTime && time.isValidTime) {
                  const existingFoodIds = new Set(time.foods.map((f) => f.id));
                  const newFoods = sourceTime.foods;
                  // .filter(
                  //   (food) => !existingFoodIds.has(food.id)
                  // );
                  return {
                    ...time,
                    foods: [...newFoods],
                    // [...time.foods, ...newFoods],
                  };
                }
                return time;
              }),
            };
          }
          return day;
        });
      });
      setHighlightedColumn(targetDayId);
      setLastDroppedCell(`day_${targetDayId}`);
    } else if (targetTime?.isValidTime) {
      setLastDroppedCell(`${targetDay.id_day}_${targetTime.id_time}`);

      setMenuDays((prevDays) => {
        const newDays = [...prevDays];

        if (draggedItem?.type === "tableCell") {
          const sourceDayIndex = newDays.findIndex(
            (day) => day.id_day === draggedItem.dayId
          );
          const sourceDay = newDays[sourceDayIndex];
          const sourceTimeIndex = sourceDay.times.findIndex(
            (time) => time.id_time === draggedItem.timeId
          );
          const sourceTime = sourceDay.times[sourceTimeIndex];

          sourceTime.foods = sourceTime.foods.filter(
            (food) => food.id !== draggedItem.food.id
          );
        }

        const targetDayIndex = newDays.findIndex(
          (day) => day.id_day === targetDay.id_day
        );
        const targetTimeIndex = newDays[targetDayIndex].times.findIndex(
          (time) => time.id_time === targetTime.id_time
        );
        const targetTimeSlot = newDays[targetDayIndex].times[targetTimeIndex];

        if (
          targetTimeSlot.isValidTime &&
          !targetTimeSlot?.foods.some((f) => f.id === draggedItem?.food.id)
        ) {
          const newFood = {
            ...draggedItem?.food,
            groups:
              selectedClassGroup?.id > 0
                ? [
                    {
                      ...selectedClassGroup,
                      ingredents: draggedItem.food.ingredents,
                      more_meal: null,
                    },
                  ]
                : group
                    ?.filter((g0) => g0.id > 0)
                    ?.map((g) => ({
                      ...g,
                      ingredents: draggedItem?.food?.ingredents || [],
                      more_meal: null,
                    })),
          };
          targetTimeSlot?.foods.push(newFood);
        } else {
          toast("Món ăn đã tồn tại!", {
            type: "error",
            autoClose: 1000,
          });
        }

        return newDays;
      });
    }
    setDraggedItem(null);
  };

  const FoodItem = ({ food, dayId, timeId }) => {
    const countGroup = food?.groups?.length;
    const countEmpty = countGroup - food?.groups?.filter(String).length;
    const groupUse = food?.groups?.filter((item) => item.id > 0);
    const foodsByGroupId =
      food.groups?.filter((group) => group.id === selectedClassGroup?.id) || [];

    if (foodsByGroupId.length === 0 && selectedClassGroup?.id > 0) {
      return <></>;
    }

    return (
      <span
        draggable={true}
        onDragStart={(e) =>
          handleDragStart(e, { type: "tableCell", dayId, timeId, food })
        }
        onDragOver={(e) => e.preventDefault()}
        className="p-1 rounded cursor-grab"
      >
        <p
          className="fw-bold mb-0"
          draggable={true}
          onDragStart={(e) =>
            handleDragStart(e, { type: "tableCell", dayId, timeId, food })
          }
          onDragOver={(e) => e.preventDefault()}
        >
          {(selectedClassGroup?.id === 0 ||
            food.groups?.filter((group) => group.id === selectedClassGroup?.id)
              ?.length > 0) &&
            food.food_name}
        </p>
        {selectedClassGroup?.id === 0 ? (
          <div
            className="small text-muted"
            // onDragOver={(e) => e.preventDefault()}
          >
            {countGroup - countEmpty === 1
              ? group?.length > 2
                ? ` (${groupUse[0].group_name}${
                    Number(groupUse[0].more_meal) > 0
                      ? `; SL:  ${groupUse[0].more_meal}`
                      : ""
                  })`
                : Number(groupUse[0].more_meal) > 0
                ? ` (SL: ${groupUse[0].more_meal})`
                : ""
              : ""}
          </div>
        ) : (
          <>
            {Number(groupUse[0].more_meal) > 0 &&
            countGroup - countEmpty === 1 &&
            selectedClassGroup?.id === groupUse[0].id ? (
              <div className="small text-muted">{` (SL: ${groupUse[0].more_meal})`}</div>
            ) : (
              <></>
            )}

            {showIngredient &&
              food.groups?.map(
                (group) =>
                  group.id === selectedClassGroup?.id && (
                    <div key={group.id} className="small">
                      {group.ingredents?.map((ing) => (
                        <div key={ing.id} className="text-muted">
                          {ing.ingredent_name}:{" "}
                          {Number(ing.ing_quantity).toFixed(2)}{" "}
                          {ing.unit_cook_name}
                        </div>
                      ))}
                    </div>
                  )
              )}
          </>
        )}
      </span>
    );
  };

  return (
    <Container fluid>
      <Row className="g-4">
        <Col md={0} lg={2} className="ps-0 d-none d-lg-block">
          <div
            className="bg-white px-2 py-2 rounded shadow"
            style={{ position: "sticky", top: "4rem" }}
          >
            <Form.Select
              className="mb-2"
              onChange={(e) => {
                setChoiceCategory(e.target.value);
              }}
              placeholder="Chọn danh mục"
            >
              <option value={0}>--Tất cả danh mục--</option>
              {category?.map((item) => {
                return <option value={item.id}>{item.category_name}</option>;
              })}
            </Form.Select>
            <Form.Control
              className="mb-2"
              onChange={(event) => {
                setFoodSearch(event.target.value);
              }}
              placeholder="Nhập tên món ăn"
            />
            <div className="overflow-auto" style={{ maxHeight: "70vh" }}>
              {foodOptions.map((food) => (
                <div
                  key={food.id}
                  draggable
                  onDragStart={(e) =>
                    handleDragStart(e, { type: "foodOption", food })
                  }
                  // onTouchStart={(e) => {
                  //   handleTouchStart(e, { type: "foodOption", food });
                  // }}
                  // onTouchMove={handleTouchMove}
                  // onTouchEnd={handleTouchEnd}
                  className="p-2 bg-light cursor-grab rounded mb-2"
                >
                  {food.food_name}
                </div>
              ))}
            </div>
          </div>
        </Col>
        <Col md={12} lg={10} className="px-0">
          <div className="table-responsive">
            <Table bordered style={{ minWidth: "800px" }}>
              <thead>
                <tr>
                  <th className="bg-light text-center align-content-center">
                    Buổi
                  </th>
                  {menuDays.map((day) => (
                    <th
                      key={day.id}
                      className={`align-content-center ${
                        hoverColumn === day.id_day
                          ? "bg-info bg-opacity-10"
                          : ""
                      }
                      ${
                        highlightedColumn === day.id_day
                          ? "bg-primary bg-opacity-10"
                          : ""
                      }
                      `}
                      draggable
                      onDragStart={(e) => {
                        handleDragStart(e, {
                          type: "day",
                          dayId: day.id_day,
                          dayData: day,
                        });
                      }}
                      onDragEnd={handleDragEnd}
                      onDragOver={(e) => handleDragOver(e, day.id_day)}
                      onDragLeave={handleDragLeave}
                      onDrop={(e) => handleDrop(e, day)}
                      // onTouchMove={handleTouchMove}
                      // onTouchEnd={handleTouchEnd}
                      // onTouchStart={(e) =>
                      //   handleTouchStart(e, {
                      //     type: "day",
                      //     dayId: day.id_day,
                      //     dayData: day,
                      //   })
                      // }
                    >
                      <div
                        className={`d-flex align-items-center justify-content-center
                        cursor-grab`}
                      >
                        <span>{day.day_name}</span>
                        {day.is_meal_saved === 1 && (
                          <Button
                            onClick={() => handleUnSavedMealDaily(day.id)}
                            variant="outline-danger ms-2"
                            title="Hủy tính khẩu phần ăn"
                            size="sm"
                          >
                            X
                          </Button>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {menuTimes.map((timeItem) => (
                  <tr key={timeItem.time_name}>
                    <th className="bg-light" draggable={true}>
                      {timeItem.time_name}
                    </th>
                    {menuDays?.map((day) => {
                      const timeSlot = day.times.find(
                        (t) => t.id_time === timeItem.id_time
                      );
                      const cellId = `${day.id_day}_${timeItem.id_time}`;
                      const canDrag =
                        timeSlot?.isValidTime &&
                        // day.is_meal_saved === 0 &&
                        timeSlot?.foods?.length > 0;

                      return (
                        <td
                          key={cellId}
                          className={`menu-table-cell pb-4 align-top ${
                            !timeSlot?.isValidTime ? "bg-light" : ""
                          }
                            ${
                              lastHoverCell === cellId ||
                              hoverColumn === day.id_day ||
                              highlightedTimeSlots === cellId
                                ? "bg-info bg-opacity-10"
                                : ""
                            }
                           ${
                             lastDroppedCell === cellId
                               ? "bg-primary bg-opacity-10"
                               : ""
                           } ${
                            highlightedColumn === day.id_day
                              ? "bg-primary bg-opacity-10"
                              : ""
                          }
                         
                          ${canDrag ? "cursor-grab" : ""}`}
                          style={{ minHeight: "100px" }}
                          draggable={canDrag}
                          onDragStart={(e) =>
                            handleDragStart(e, {
                              type: "timeSlot",
                              dayId: day.id_day,
                              timeId: timeItem.id_time,
                              foods: timeSlot?.foods || [],
                            })
                          }
                          onDragEnd={handleDragEnd}
                          onDragOver={(e) =>
                            handleDragOver(e, day.id_day, timeItem.id_time)
                          }
                          onDragLeave={handleDragLeave}
                          onDrop={(e) => handleDrop(e, day, timeSlot)}
                          onTouchMove={handleTouchMove}
                          onTouchEnd={handleTouchEnd}
                          onTouchStart={(e) =>
                            handleTouchStart(e, {
                              type: "timeSlot",
                              dayId: day.id_day,
                              timeId: timeItem.id_time,
                              foods: timeSlot?.foods || [],
                            })
                          }
                          onClick={() => {
                            if (timeSlot?.isValidTime)
                              if (day.is_meal_saved === 0) {
                                handleShowModal({
                                  id_day: day.id_day,
                                  day_name: day.day_name,
                                  id_time: timeItem.id_time,
                                  time_name: timeItem.time_name,
                                  foods: timeSlot?.foods,
                                });
                              } else
                                toast("Đã lưu Tính khẩu phần ăn.", {
                                  type: "error",
                                  autoClose: 1000,
                                });
                          }}
                        >
                          {timeSlot?.foods.map((food) => (
                            <FoodItem
                              key={food.id}
                              food={food}
                              dayId={day.id_day}
                              timeId={timeItem.id_time}
                            />
                          ))}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <Table
            bordered
            style={{ minWidth: 999 }}
            id="menu-table"
            className="d-none"
          >
            <thead>
              <tr>
                <th colSpan={Math.floor((menuDays.length + 1) / 2)}>
                  Học sinh
                </th>
                <th colSpan={Math.ceil((menuDays.length + 1) / 2)}>
                  Thực đơn Tháng {startDate.getMonth() + 1} (Tuần {weekInMonth}){" "}
                  {stringFirstDate(startDate)} - {stringLastDate(termDate)}
                </th>
              </tr>

              <tr className="text-center">
                <th>Buổi</th>
                {menuDays.map((day) => {
                  return <th key={day.id}>{day.day_name}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {menuTimes.length > 0 && menuDays.length > 0 ? (
                menuTimes.map((timeItem) => {
                  return (
                    <tr key={timeItem.time_name}>
                      <th className="text-center">{timeItem.time_name}</th>

                      {menuDays.length > 0
                        ? menuDays.map((day) => {
                            let timesWithDay = day.times.filter(
                              (time) => time.id_time === timeItem.id_time
                            );

                            return (
                              <td key={day.id} className="menu-table-cell">
                                {timesWithDay[0]?.foods.length > 0
                                  ? timesWithDay[0]?.foods.map(
                                      (food, foodIndex) => {
                                        const countGroup = food.groups.length;
                                        const countEmpty =
                                          countGroup -
                                          food.groups.filter(String).length;
                                        const groupUse = food.groups.filter(
                                          (item) => item.id > 0
                                        );
                                        return (
                                          <div key={food.id}>
                                            {Number(selectedClassGroup.id) ===
                                            0 ? (
                                              <>
                                                <strong>
                                                  {food.food_name}
                                                </strong>

                                                {countGroup - countEmpty === 1
                                                  ? group?.length > 2
                                                    ? ` (${
                                                        groupUse[0].group_name
                                                      }${
                                                        Number(
                                                          groupUse[0].more_meal
                                                        ) > 0
                                                          ? `; SL:  ${groupUse[0].more_meal}`
                                                          : ""
                                                      })`
                                                    : Number(
                                                        groupUse[0].more_meal
                                                      ) > 0
                                                    ? ` (SL: ${groupUse[0].more_meal})`
                                                    : ""
                                                  : ""}
                                              </>
                                            ) : (
                                              <strong>
                                                {food.groups.some(
                                                  (groupItem) =>
                                                    Number(groupItem.id) ===
                                                    Number(
                                                      selectedClassGroup.id
                                                    )
                                                )
                                                  ? food.food_name
                                                  : ""}
                                              </strong>
                                            )}

                                            {Number(selectedClassGroup.id) !==
                                            0 ? (
                                              <br />
                                            ) : (
                                              <></>
                                            )}

                                            {Number(selectedClassGroup.id) !== 0
                                              ? groupUse
                                                  .find(
                                                    (groupItem) =>
                                                      groupItem.id ===
                                                      Number(
                                                        selectedClassGroup.id
                                                      )
                                                  )
                                                  ?.ingredents?.map(
                                                    (
                                                      ingredienItem,
                                                      ingredientItemIndex
                                                    ) => {
                                                      return (
                                                        <div
                                                          key={ingredienItem.id}
                                                        >
                                                          {
                                                            ingredienItem.ingredent_name
                                                          }{" "}
                                                          <CurrencyFormat
                                                            value={parseFloat(
                                                              ingredienItem.ing_quantity
                                                            )}
                                                            displayType={"text"}
                                                            thousandSeparator={
                                                              true
                                                            }
                                                            decimalScale={2}
                                                          />
                                                          {
                                                            ingredienItem.unit_cook_name
                                                          }
                                                          {ingredientItemIndex ===
                                                          groupUse.find(
                                                            (groupItem) =>
                                                              groupItem.id ===
                                                              Number(
                                                                selectedClassGroup.id
                                                              )
                                                          )?.ingredents.length -
                                                            1 ? (
                                                            ""
                                                          ) : (
                                                            <br />
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  )
                                              : ""}

                                            {timesWithDay[0]?.foods.length -
                                              1 ===
                                            foodIndex ? (
                                              ""
                                            ) : (
                                              <>
                                                <br />
                                                <br />
                                              </>
                                            )}
                                          </div>
                                        );
                                      }
                                    )
                                  : "empty"}
                              </td>
                            );
                          })
                        : ""}
                    </tr>
                  );
                })
              ) : (
                <></>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default MenuExcelTemplate0;
